@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Black.eot');
  src: local('Big Shoulders Display Black'), local('BigShouldersDisplay-Black'),
    url('./assets/fonts/BigShouldersDisplay-Black.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Black.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Black.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Black.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Black.svg#BigShouldersDisplay-Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-ExtraBold.eot');
  src: local('Big Shoulders Display ExtraBold'),
    local('BigShouldersDisplay-ExtraBold'),
    url('./assets/fonts/BigShouldersDisplay-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-ExtraBold.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-ExtraBold.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-ExtraBold.svg#BigShouldersDisplay-ExtraBold')
      format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Bold.eot');
  src: local('Big Shoulders Display Bold'), local('BigShouldersDisplay-Bold'),
    url('./assets/fonts/BigShouldersDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Bold.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Bold.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Bold.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Bold.svg#BigShouldersDisplay-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Light.eot');
  src: local('Big Shoulders Display Light'), local('BigShouldersDisplay-Light'),
    url('./assets/fonts/BigShouldersDisplay-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Light.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Light.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Light.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Light.svg#BigShouldersDisplay-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Medium.eot');
  src: local('Big Shoulders Display Medium'),
    local('BigShouldersDisplay-Medium'),
    url('./assets/fonts/BigShouldersDisplay-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Medium.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Medium.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Medium.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Medium.svg#BigShouldersDisplay-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Regular.eot');
  src: local('Big Shoulders Display Regular'),
    local('BigShouldersDisplay-Regular'),
    url('./assets/fonts/BigShouldersDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Regular.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Regular.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Regular.svg#BigShouldersDisplay-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-SemiBold.eot');
  src: local('Big Shoulders Display SemiBold'),
    local('BigShouldersDisplay-SemiBold'),
    url('./assets/fonts/BigShouldersDisplay-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-SemiBold.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-SemiBold.svg#BigShouldersDisplay-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Shoulders Display';
  src: url('./assets/fonts/BigShouldersDisplay-Thin.eot');
  src: local('Big Shoulders Display Thin'), local('BigShouldersDisplay-Thin'),
    url('./assets/fonts/BigShouldersDisplay-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/BigShouldersDisplay-Thin.woff2') format('woff2'),
    url('./assets/fonts/BigShouldersDisplay-Thin.woff') format('woff'),
    url('./assets/fonts/BigShouldersDisplay-Thin.ttf') format('truetype'),
    url('./assets/fonts/BigShouldersDisplay-Thin.svg#BigShouldersDisplay-Thin')
      format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/Dosis-Bold.eot');
  src: local('Dosis Bold'), local('Dosis-Bold'),
    url('./assets/fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Dosis-Bold.woff2') format('woff2'),
    url('./assets/fonts/Dosis-Bold.woff') format('woff'),
    url('./assets/fonts/Dosis-Bold.ttf') format('truetype'),
    url('./assets/fonts/Dosis-Bold.svg#Dosis-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/Dosis-Light.eot');
  src: local('Dosis Light'), local('Dosis-Light'),
    url('./assets/fonts/Dosis-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Dosis-Light.woff2') format('woff2'),
    url('./assets/fonts/Dosis-Light.woff') format('woff'),
    url('./assets/fonts/Dosis-Light.ttf') format('truetype'),
    url('./assets/fonts/Dosis-Light.svg#Dosis-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/Dosis-Medium.eot');
  src: local('Dosis Medium'), local('Dosis-Medium'),
    url('./assets/fonts/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Dosis-Medium.woff2') format('woff2'),
    url('./assets/fonts/Dosis-Medium.woff') format('woff'),
    url('./assets/fonts/Dosis-Medium.ttf') format('truetype'),
    url('./assets/fonts/Dosis-Medium.svg#Dosis-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/Dosis-Regular.eot');
  src: local('Dosis Regular'), local('Dosis-Regular'),
    url('./assets/fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Dosis-Regular.woff2') format('woff2'),
    url('./assets/fonts/Dosis-Regular.woff') format('woff'),
    url('./assets/fonts/Dosis-Regular.ttf') format('truetype'),
    url('./assets/fonts/Dosis-Regular.svg#Dosis-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dosis';
  src: url('./assets/fonts/Dosis-SemiBold.eot');
  src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
    url('./assets/fonts/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Dosis-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Dosis-SemiBold.woff') format('woff'),
    url('./assets/fonts/Dosis-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/Dosis-SemiBold.svg#Dosis-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
