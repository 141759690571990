.toolo-select__value-container {
  padding: 0.32rem 0.75rem;
  font-weight: 600;
  font-size: var(--text-sm);
}

.toolo-select__control {
  color: var(--color-theme1);
  background-color: #fbf8f6 !important;
}

.toolo-select__indicator__image {
  max-height: 22px;
}

.toolo-select__indicator {
  padding-left: 0 !important;
  padding-right: 23px !important;
}
