.healthNotify {
  position: fixed;
  bottom: calc(50% + 10px);
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  left: 0;
  color: var(--color-white);
  padding: 2px;
  font-size: 10px;
  border-radius: 5px;
  text-align: center;
}

.healthNotify img {
  height: 100%;
}

.healthNotify:hover {
  opacity: 1;
}

.safety-popover {
  font-family: var(--font-family-monospace) !important;
  font-size: 16px !important;
  letter-spacing: 0.8px !important;
  z-index: 9999 !important;
}

.safety-popover .popover-header {
  background-color: var(--color-theme);
  color: #ffffff;
  font-size: 16px !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
